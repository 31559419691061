import { Flex, HStack, Icon, Stack, StackDivider, Text } from '@chakra-ui/react'
import BathroomIcon from 'icons/bathroom.svg'
import BedIcon from 'icons/bed.svg'
import GuestIcon from 'icons/guest.svg'
import { useMemo } from 'react'
import { PropertyLocation } from './PropertyLocation'

const iconSizeMap = {
  md: 5,
  sm: 4
}

export const PropertyDetailHeader = ({
  property,
  size = 'md',
  direction = 'row',
  spacing = '2',
  ...rest
}) => {
  const iconSize = iconSizeMap[size]
  const hideType = !property.prop_type || property.display_type === false
  const useRanges = !!property.occupancy_ranges
  const bathCount = useMemo(() => {
    if (useRanges) {
      return `${property.occupancy_ranges?.baths?.min} - ${property.occupancy_ranges?.baths?.max}`
    } else {
      return parseFloat(property.baths)
    }
  }, [
    property.baths,
    property.occupancy_ranges?.baths?.max,
    property.occupancy_ranges?.baths?.min,
    useRanges
  ])
  const bedCount = useMemo(() => {
    if (useRanges) {
      return `${property.occupancy_ranges?.bedrooms?.min} - ${property.occupancy_ranges?.bedrooms?.max}`
    } else {
      return parseFloat(property.beds)
    }
  }, [
    property.beds,
    property.occupancy_ranges?.bedrooms?.max,
    property.occupancy_ranges?.bedrooms?.min,
    useRanges
  ])
  const occupancy = useMemo(() => {
    if (useRanges) {
      return `${property.occupancy_ranges?.occupancy?.min} - ${property.occupancy_ranges?.occupancy?.max}`
    } else {
      return property.occu ?? '--'
    }
  }, [
    property.occu,
    property.occupancy_ranges?.occupancy?.max,
    property.occupancy_ranges?.occupancy?.min,
    useRanges
  ])

  return (
    <Stack
      divider={
        <StackDivider
          display={{
            base: 'none',
            md: direction === 'column-reverse' ? 'none' : 'flex'
          }}
        />
      }
      direction={direction}
      w="full"
      spacing={spacing}
      {...rest}
    >
      <HStack divider={<StackDivider />} wrap="wrap" spacing="3">
        <Flex alignItems="center" fontSize={size}>
          <Icon as={BedIcon} w={iconSize} h={iconSize} fill={'gray.500'} />
          <Text as="span" color="gray.800" ml="2">
            {bedCount}
          </Text>
        </Flex>
        <Flex alignItems="center" fontSize={size}>
          <Icon as={BathroomIcon} w={iconSize} h={iconSize} fill={'gray.500'} />
          <Text as="span" color="gray.800" ml="2">
            {bathCount}
          </Text>
        </Flex>
        <Flex alignItems="center" fontSize={size}>
          <Icon as={GuestIcon} w={iconSize} h={iconSize} fill={'gray.500'} />
          <Text as="span" color="gray.800" ml="2">
            {occupancy}
          </Text>
        </Flex>
        {!hideType && (
          <Flex alignItems="center" fontSize={size}>
            <Text as="span" color="gray.800">
              {property.prop_type}
            </Text>
          </Flex>
        )}
      </HStack>
      {direction !== 'column-reverse' && (
        <PropertyLocation property={property} />
      )}
    </Stack>
  )
}
