import { useSignalContext } from '@/contexts/signal'
import { Box, Heading } from '@chakra-ui/react'

export function PageTitle({ children, ...props }) {
  const { standalone } = useSignalContext()
  return standalone ? (
    <Heading
      as="h1"
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    />
  ) : (
    <Box className="main_color">
      <Box
        as="h1"
        role="heading"
        dangerouslySetInnerHTML={{ __html: children }}
        {...props}
      />
    </Box>
  )
}
