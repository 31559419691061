import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

export const PageDescription = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <Box className="main_color">
        <Flex
          ref={ref}
          className="page-description"
          direction="column"
          {...props}
          sx={{
            p: { margin: '0.85em 0' },
            a: {
              color: 'wpMain.primary'
            }
          }}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </Box>
    )
  }
)

PageDescription.displayName = 'PageDescription'
