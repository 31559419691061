import { Flex, HStack, StackDivider, Text } from '@chakra-ui/react'

export function PropertyLocation({ property, ...rest }) {
  const hideLocation = !property.location || property.display_location === false
  const hideArea = !property.area || property.display_area === false
  const hideCity = !property.city || property.display_city === false
  const hideState = !property.state || property.display_state === false

  if (hideLocation && hideArea && hideCity && hideState) {
    return null
  }
  return (
    <HStack divider={<StackDivider />} wrap="wrap" {...rest} spacing="3">
      {!hideLocation && (
        <Flex alignItems="center" fontSize={'sm'}>
          <Text as="span" color="gray.600">
            {property.location}
          </Text>
        </Flex>
      )}
      {!hideArea && (
        <Flex alignItems="center" fontSize={'sm'}>
          <Text as="span" color="gray.600">
            {property.area}
          </Text>
        </Flex>
      )}
      {!hideCity && (
        <Flex alignItems="center" fontSize={'sm'}>
          <Text as="span" color="gray.600">
            {property.city}
          </Text>
        </Flex>
      )}
      {!hideState && (
        <Flex alignItems="center" fontSize={'sm'}>
          <Text as="span" color="gray.600">
            {property.state}
          </Text>
        </Flex>
      )}
    </HStack>
  )
}
