import { useSignalContext } from '@/contexts/signal'
import {
  cleanParams,
  stripBasePath,
  stripTrailingSlash
} from '@/lib/utils'
import omitBy from 'lodash/omitBy'
import { useRouter } from 'next/router'
import React from 'react'

const signalClientPrefix = process.env.NEXT_PUBLIC_SIGNAL_CLIENT_PREFIX

const isLocalPath = (p) => !p.startsWith('/')

const getBasePath = ({ standalone, token, rootSlug }) => {
  const basePath = standalone ? `/${token}` : `/${rootSlug}${signalClientPrefix}`

  return basePath
}

export default function useSignalPath() {
  const router = useRouter()
  const { rootSlug, standalone, token } = useSignalContext()

  return React.useCallback(
    (path = '', { query, preserve = false } = {}) => {
      const [cleanPath, pathParams] = path.split('?')

      let queryParams
      if (!query && pathParams) {
        queryParams = cleanParams(
          Object.fromEntries(new URLSearchParams(pathParams))
        )
      } else {
        queryParams = query
      }

      const staticPath = isLocalPath(cleanPath)
        ? stripBasePath({ standalone, token, rootSlug, path: router.asPath }) +
        '/' +
        cleanPath
        : cleanPath

      const basePath = getBasePath({
        standalone,
        token,
        rootSlug
      })

      const curQuery = router.asPath.split('?')[1]
      const fullPath = basePath + staticPath

      let queryString = ''
      if (queryParams) {
        const paramsObj = preserve
          ? Object.fromEntries(new URLSearchParams(curQuery))
          : {}
        const validParams = omitBy(
          { ...paramsObj, ...queryParams },
          (v) => v == null
        )
        if (Object.keys(validParams).length) {
          queryString = `?${new URLSearchParams(validParams).toString()}`
        }
      } else {
        if (curQuery) {
          queryString = `?${new URLSearchParams(curQuery).toString()}`
        }
      }

      const finalPath = `${stripTrailingSlash(fullPath)}${queryString}`

      return finalPath
    },
    [rootSlug, router.asPath, standalone, token]
  )
}
